.App {
  user-select: none;
  height: 100vh;
  width: 100%;
  font-family: "Oswald", sans-serif;
  overflow: hidden;
}
.p_nav {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #0d0726;
  height: 119px;
}
.p_nav_p {
  all: unset;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 1rem;
}
.carousel_container {
  width: 100%;
  background-color: #001a33;
}

.carousel {
  width: 100%;
}

.carousel_image {
  height: 440px;
  padding-top: 5rem;
  padding-bottom: 6rem;
  width: 90%;
}

.infoz {
  display: flex;
  background-color: #0d0726;
  justify-content: space-between;
  width: 100%;
  /* padding-top: 1rem; */
}

h2,
p {
  margin: 0;
}

.infoh2 {
  width: 100%;
  text-align: center;
  padding: 1.8rem 0rem;
  border-bottom: none;
  border-top: none;
  font-size: 1.67rem;
  border-bottom: 3px solid #2d0236;
  color: white;
}
.infoh2:nth-child(1) {
  border-left: none;
}
.infoh2:last-child {
  border-right: none;
}
.infoztext {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 355px;
  border: 1px solid #2d0236;
  border-bottom: none;
  border-top: none;
  align-items: center;
  color: white;
  /* padding: 0.98em 0rem; */
  background-color: rgba(0, 0, 0, 0.3) !important;
  z-index: 123123;
}
.infoztext:nth-child(1) {
  border-left: none;
  background-image: url("../public/banner_loto.jpg") !important;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
}
.infoztext:nth-child(2) {
  /* border-left: none; */
  background-image: url("../public/banner_dog.jpg") !important;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
}
.infoztext:nth-child(3) {
  /* border-left: none; */
  background-image: url("../public/banner_rlt.jpg") !important;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
}
.infoztext:nth-child(4) {
  /* border-left: none; */
  background-image: url("../public/banner_keno.jpg") !important;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
}
.infoztext:last-child {
  border-right: none;
  background-image: url("../public/banner_loto.jpg") !important;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
}

.infonumb {
  display: flex;
  /* flex-direction: column; */
  justify-content: center;
  gap: 1rem;
  align-items: center;
  width: 100%;
  height: 100%;
  font-size: 3rem;
  /* color: #f8db27; */
  color: #feda00;
  /* border: 1px solid red; */
  font-weight: 600;
}

.infonumb > span {
  font-size: 1.2rem;
  color: white;
  margin-top: 24px;

  /* color: #; */
}
